import React, { useMemo } from "react";
import {
  Document,
  Page,
  PDFDownloadLink,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { Button } from "antd";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import logo from "@/images/signature-logo.png";
import { statusTypes } from "@/types/enum";

const styles = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    paddingTop: 35,
    paddingBottom: 0,
    paddingHorizontal: 35,
    fontSize: 12,
    fontWeight: "thin",
  },
  title: {
    display: "flex",
    fontWeight: 900,
    textAlign: "left",
    marginBottom: 18,
  },
  subtitle: {
    display: "flex",
    lineHeight: 1.8,
    fontWeight: 700,
    fontSize: 20,
  },

  text: {
    display: "flex",
    width: "100%",
    margin: "0 0 24px 0",
    textAlign: "justify",
    lineHeight: 1.5,
    fontWeight: "thin",
    color: "#333",
  },

  subtext: {
    display: "flex",
    width: "100%",
    lineHeight: 1.5,
    fontWeight: 500,
    color: "#333",
  },
  images: {
    width: 228,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  header_logo: {
    width: "100%",
  },
  header_view: {
    marginLeft: 20,
  },
});

export const OrderPdf = ({ detail, className = "" }: any) => {
  const {
    store: { isMobile },
  } = useModel(view);
  return useMemo(
    () => (
      <PDFDownloadLink document={<OrderDocument detail={detail} />}>
        {({ loading }) => (
          <Button
            type="primary"
            className={className}
            style={isMobile ? { width: "100%" } : {}}
          >
            {loading ? "loading..." : "Save as pdf"}
          </Button>
        )}
      </PDFDownloadLink>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
};

const OrderDocument = ({ detail }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header}>
          <Image style={styles.header_logo} src={logo}></Image>
        </View>
        <Text style={styles.title}>Invoice ID: {detail?.orderId}</Text>
        <Text style={styles.text}></Text>

        <Text style={styles.subtitle}>Billing information</Text>
        <Text style={styles.text}>Transaction ID: {detail?.transactionId}</Text>
        <Text style={styles.text}>Payment method: {detail?.paymentMethod}</Text>
        <Text style={styles.text}>
          Date paid:{" "}
          {(detail?.createDate &&
            dayjs(detail?.createDate).format("MM/DD/YYYY")) ||
            "N/A"}
        </Text>

        <Text style={styles.subtitle}>Issued to</Text>
        <Text style={styles.text}>Name: {detail?.issuedTo?.name}</Text>
        <Text style={styles.text}>
          Email address: {detail?.issuedTo?.email}
        </Text>
        <Text style={styles.text}>
          Billing address: {detail?.issuedTo?.address}
        </Text>

        <Text style={styles.subtitle}>Issued by</Text>
        <Text style={styles.text}>Name: {detail?.issuedBy?.name}</Text>
        <Text style={styles.text}>
          Email address: {detail?.issuedBy?.email}
        </Text>
        <Text style={styles.text}>
          Billing address: {detail?.issuedBy?.address}
        </Text>

        <Text style={styles.subtitle}>Invoice summary</Text>
        <Text style={styles.text}>Product type: {detail?.productType}</Text>
        <Text style={styles.text}>
          Donation type: {detail?.donationType || "N/A"}
        </Text>
        <Text style={styles.text}>
          Frequency: {detail?.frequency === "once" ? "One time" : "Recurring"}
        </Text>
        <Text style={styles.text}>
          Payment method: {detail?.paymentMethod || "N/A"}
        </Text>
        <Text style={styles.text}>
          Sub total amount:{" "}
          {Number(detail?.subTotal ?? 0).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </Text>
        <Text style={styles.text}>
          Discount:{" "}
          {Number(detail?.discount ?? 0).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </Text>
        <Text style={styles.text}>Coupon: {detail?.coupon || "N/A"}</Text>
        <Text style={styles.text}>
          Tax:{" "}
          {Number(detail?.tax ?? 0).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </Text>
        <Text style={styles.text}>
          Total amount:{" "}
          {Number(
            Number(detail?.subTotal ?? 0) -
              Number(detail?.discount ?? 0) +
              Number(detail?.tax ?? 0) -
              Number(detail?.fees ?? 0)
          ).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </Text>

        <Text style={styles.subtitle}>Donor note</Text>
        <Text style={styles.text}>{detail?.donorNote || "N/A"}</Text>
        {/* <Text style={styles.subtitle}>Admin note</Text>
        <Text style={styles.text}>{detail?.adminNote || "N/A"}</Text> */}
        <Text style={styles.subtitle}>Transaction tags</Text>
        <Text style={styles.text}>{detail?.tags || "N/A"}</Text>
        <Text style={styles.subtitle}>Approved for QB</Text>
        <Text style={styles.text}>
          {detail?.approveQuickBook ? "Yes" : "No"}
        </Text>
        <Text style={styles.subtitle}>Transaction status</Text>
        <Text style={styles.text}>
          {detail?.status
            ? statusTypes[detail.status]?.label || detail.status
            : "N/A"}
        </Text>
        <Text style={styles.subtitle}>Scheduled payment date</Text>
        <Text style={styles.text}>
          {detail?.schedulePaymentDate ? detail?.schedulePaymentDate : "N/A"}
        </Text>
      </Page>
    </Document>
  );
};
