import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Tag,
  Upload,
} from "antd";
import dayjs from "dayjs";
import DatePicker from "@/components/DatePicker";
import useConfirm from "@/components/Modal/Confirm";

// import { disciplineOptions as options } from "@/types/enum";

import { eventRegistration, eventOrderDelete, eventUploadRegistration } from "./../../../apis/event-api";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
// import { ReactComponent as Filter } from "@/images/filter.svg";
import "./../../admin_artists/admin_artists.less";
import queryString from "query-string";
import { stateList, UserRole } from "@/types/enum";
import {
  admin_router_finance_order_detail,
} from "@/adminApp/routers";

export const tags = [
  "Arts Organizations",
  "Board of Directors",
  "Funding Organization",
  "DO NOT CONTACT",
  "Potential Donor",
  "Elected Official",
  "Artist Council",
  "Report NOT Received",
  "Former Board of Directors",
  "Former Artist Council",
];

export const roles = [
  {
    label: "Artist",
    value: "artist",
  },
  {
    label: "Donor",
    value: "donor",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

export const EventRegistration = () => {
  const history = useHistory();

  const { id: eventId, registration } = useParams() as any;

  const { component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      Are you sure you want to permanently delete this account ?
    </div>,
    {
      title: "Delete",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (orderId?: any) => {
        eventOrderDelete({
          orderId,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, date = ["", ""], ...others } = params,
      [submitFromDate, submitToDate] = date;
    window.open(
      `${process.env.REACT_APP_API_HOST
      }/event/admin/export-registration?${queryString.stringify({
        submitFromDate,
        submitToDate,
        ...others,
        exportId: selectedRows.map((item: any) => item.id).join(","),
        eventId,
      })}`,
      "_blank"
    );
  };

  // -view note
  const handleToOrder = (id: any) => {
    history.push(
      admin_router_finance_order_detail.replace(":id/:edit(edit)?", id)
    );
  };

  // const handleDelete = (row: any) => {
  //   deleteShow(row.orderId);
  // };

  //handler end

  // -search
  const column: any[] = [
    {
      title: "Name",
      dataIndex: "firstName",
      fixed: "left",
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      width: 160,
      render: (_: any, row: any) =>
        row?.firstName + " " + (row?.lastName || ""),
    },

    {
      title: "email address",
      dataIndex: "email",
    },
    {
      title: "guest type",
      dataIndex: "sequence",
      render: (text: number) => (typeof text === 'number' ? (text > 1 ? "Guest" : "Primary contact") : text),
    },
    {
      title: "user type",
      width: 130,
      dataIndex: "userRole",
      render: (roles: string[] = []) => {
        const tags = []
        if (roles.includes(UserRole.PREMIUM)) {
          tags.push({ color: 'green', text: 'Sponsored Artist' })
        } else if (roles.includes(UserRole.ARTIST)) {
          tags.push({ color: 'blue', text: 'Free Artist' })
        }

        if (roles.includes(UserRole.DONOR)) {
          tags.push({ color: 'purple', text: 'Donor' })
        }

        if (roles.includes('N/A')) {
          tags.push({ color: 'grey', text: 'N/A' })
        }
        if (!tags?.length) {
          tags.push({ color: 'grey', text: 'Guest' })
        }

        return tags.map(t => <Tag color={t.color}>{t.text}</Tag>)
      },
    },
    {
      title: "Order number",
      dataIndex: "orderId",
      width: 180,
      align: 'center',
      render: (text: number, row: any) =>
        `ID${text}${typeof row.sequence === 'number' ? "-" + row.sequence : ''}`,
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      width: 80,
      align: 'center'
    },
    {
      title: "Paid",
      dataIndex: "paid",
      render: (text: string) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Date submitted",
      dataIndex: "createDate",
      key: "createDate",
      width: 180,
      align: 'center',
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleToOrder(row.id)}>
                  View Order
                </Menu.Item>
                {/*<Menu.Item*/}
                {/*  onClick={() =>*/}
                {/*    history.push(*/}
                {/*      admin_router_message_to.replace(":to", row.userId)*/}
                {/*    )*/}
                {/*  }*/}
                {/*>*/}
                {/*  Message user*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item onClick={() => handleDelete(row)}>*/}
                {/*  Delete Order*/}
                {/*</Menu.Item>*/}
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });

  const [total, setTotal] = useState(0);
  const [summary, setSummary] = useState<any>();
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);
  // const handleViewFilter = () => {
  //   setFilterModal(true);
  // };
  const handleFilterFinish = (values: any) => {
    setSelectedRows([]);
    setTableData([]);
    setParams({
      ...params,
      ...values,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  // const handleReset = () => {
  //   filterForm.resetFields();
  //   setText("");
  //   setParams({ page: 1, limit: 10, keyword: "" });
  // };

  const [upFile, setUpFile] = useState<any>()
  const { setShow: showUpload, component: uploadComp } = useConfirm(
    <div>
      <Upload
        accept=".csv"
        maxCount={1}
        fileList={upFile ? [upFile] : undefined}
        beforeUpload={() => false}
        onChange={info => {
          setUpFile(info.file)
        }}>
        <Button>Choose File</Button>
      </Upload>
    </div>,
    {
      title: 'Are you sure to upload registration for this event?',
      okText: 'upload',

      onOk: async () => {
        showUpload(false)
        setUpFile(undefined)
        if (upFile) {
          const res = await eventUploadRegistration({ id: eventId, file: upFile })
          if (res?.success) {
            message.success('upload success!')
            getList()
          }
        } else {
          message.error('no csv file')
        }
      }
    }
  )

  const getList = () => {
    const { date = [], ...others } = params;
    let [submitFromDate, submitToDate] = date;
    let _data = {
      eventId,
      submitToDate,
      submitFromDate,
      ...others,
    };
    eventRegistration(_data)
      .then(({ items, meta, summary }: any) => {
        setTableData(items);
        setSummary(summary);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
        setSummary(null);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="admin-artists-container admin-common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/program/field">Event</Breadcrumb.Item>
        <Breadcrumb.Item>View application</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="flex">
        {registration?.split(/-/)[1]}{" "}
        {/*<Button type="primary">send message</Button>*/}
      </h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          {/*<div className="range-picker-wrap field-form-items">*/}
          {/*  <span>due date</span>*/}
          {/*  <DatePicker.RangePicker*/}
          {/*    onChange={(e) => {*/}
          {/*      setTableData([]);*/}
          {/*      setParams({*/}
          {/*        ...params,*/}
          {/*        page: 1,*/}
          {/*        limit: 10,*/}
          {/*        date: e,*/}
          {/*      });*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="range-picker-wrap field-form-items">
            <span>submit date</span>
            <DatePicker.RangePicker
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e || ["", ""],
                });
              }}
            />
          </div>
          {/*<div className="field-form-items">*/}
          {/*  <span>application Status</span>*/}
          {/*  <Select*/}
          {/*    allowClear*/}
          {/*    value={params?.discipline}*/}
          {/*    bordered={false}*/}
          {/*    onChange={(e) => {*/}
          {/*      setSelectedRows([]);*/}
          {/*      setTableData([]);*/}
          {/*      setParams({*/}
          {/*        ...params,*/}
          {/*        page: 1,*/}
          {/*        limit: 10,*/}
          {/*        discipline: e,*/}
          {/*      });*/}
          {/*    }}*/}
          {/*    placeholder="select... "*/}
          {/*  >*/}
          {/*    {options.map((item) => (*/}
          {/*      <Select.Option key={item} value={item}>*/}
          {/*        {item}*/}
          {/*      </Select.Option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</div>*/}
          {/*<div className="filter-item" onClick={handleViewFilter}>*/}
          {/*  <Filter />*/}
          {/*</div>*/}
          {/*<span className="clear-all" onClick={handleReset}>*/}
          {/*  Clear*/}
          {/*</span>*/}
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
              <Menu.Item onClick={() => showUpload(true)}>Upload Registration CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      <div className="data-chart-container flex">
        <span>
          <i>Number of Registrations</i>
          <i>{summary?.registrations || 0}</i>
        </span>
        <span>
          <i>Inventory</i>
          <i>{summary?.inventory > -1 ? summary?.inventory : "Unlimited"}</i>
        </span>
        <span>
          <i>Revenue</i>
          <i>
            {Number(summary?.revenue || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            }) || 0}
          </i>
        </span>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Applications</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                })
              }
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-artist-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <Form.Item label="Search admin note" name="keyword">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Divider />
          <h4>Data ranges</h4>
          <Form.Item label="membership expiry date" name="membershipDate">
            <DatePicker.RangePicker placeholder={["From", "To"]} />
          </Form.Item>
          <Form.Item label="account create" name="createDate">
            <DatePicker.RangePicker
              placeholder={["From", "To"]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
          <Form.Item label="SA Contract" name="signDate">
            <DatePicker.RangePicker
              placeholder={["From", "To"]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
          <Divider />
          <h4>Location</h4>
          <Form.Item label="City" name="city">
            <Input placeholder="City" maxLength={30} />
          </Form.Item>
          <Form.Item label="State" name="state" className="select-container">
            <Select bordered={false} placeholder="State">
              {Object.values(stateList)?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="country"
            name="country"
            className="select-container"
            initialValue="United States"
          >
            <Select bordered={false} placeholder="country">
              <Select.Option value="United States">United States</Select.Option>
            </Select>
          </Form.Item>
          <Divider />
          <h4>Years of experience</h4>
          <div className="flex">
            <Form.Item label="Years of experience" name="experienceYear">
              <Select bordered={false} placeholder="Select..." mode="multiple">
                <Select.Option value="0-5">0 to 5 years </Select.Option>
                <Select.Option value="5-10 ">5 to 10 years </Select.Option>
                <Select.Option value="10-50">More than 10 years</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {deleteComponent}
      {uploadComp}
    </div>
  );
};
