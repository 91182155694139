import { Consultation } from "./data/typings";
import { Descriptions, Modal, Tag } from "antd";
import {
  ConsultationStatus,
  formatDateTimeAndTimezone,
  formatDuration,
  statusMap,
} from "@/adminApp/pages/admin_consultation/scheduling/shared";
import "./BookingDetailModal.less";

type Props = {
  consultation?: Consultation;
  open: boolean;
  setOpen: (open: boolean) => void;
  setConsultation: (val?: Consultation) => void;
  type: "user" | "admin";
};

const BookingDetailModal = ({
  consultation,
  open,
  setOpen,
  setConsultation,
  type,
}: Props) => {
  const startTimeString = formatDateTimeAndTimezone(consultation?.startTime);
  const amountString = Number(consultation?.order.amount).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
  const discpuntString = Number(consultation?.order.discount).toLocaleString(
    "en",
    {
      style: "currency",
      currency: "USD",
    }
  );
  const status = consultation?.status as ConsultationStatus;
  return (
    <Modal
      title={
        <div className="flex justify-start items-center gap-4">
          <h3 className="m-0">Booking Detail</h3>
          <Tag color={status ? statusMap[status]?.color : "gray"}>
            {statusMap[status] ? statusMap[status]?.text : status}
          </Tag>
        </div>
      }
      visible={open}
      onCancel={() => {
        setOpen(false);
        setConsultation(undefined);
      }}
      footer={null}
      width="60vw"
    >
      <Descriptions
        layout="vertical"
        bordered
        column={2}
        className="booking-detail"
        size="small"
      >
        {type === "user" ? (
          <Descriptions.Item label="Admin Name">
            {consultation?.staff?.name}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label="Artist Name">
            {consultation?.artistUser?.profile?.fullName}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Event Name">
          {consultation?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Topic">
          {consultation?.topic}
        </Descriptions.Item>
        <Descriptions.Item label="Types of consultations">
          {formatDuration(consultation?.duration)}
        </Descriptions.Item>
        <Descriptions.Item label="Date & Time">
          {startTimeString[0]}
        </Descriptions.Item>
        <Descriptions.Item label="Timezone">
          {startTimeString[1]}
        </Descriptions.Item>
        <Descriptions.Item label="Price">{amountString}</Descriptions.Item>
        <Descriptions.Item label="Discount">{discpuntString}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default BookingDetailModal;
