import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import { PriceModel } from "../staff/shared";
import { localStorageGet } from "@/utils/storage";

dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const TIME_ZONE = "America/New_York";

export const formatDuration = (minutes = 0) => {
  return dayjs.duration({ minutes }).humanize().replace(/an?\s/, "1 ");
};

export const formatDateTime = (date?: number | Date) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("MM/DD/YYYY • h:mm A z").replace(":00", "");
};

export const formatDateTimeAndTimezone = (date?: number | Date) => {
  if (!date) {
    return [];
  }
  return dayjs(date)
    .tz(TIME_ZONE)
    .format("dddd, MMMM DD, YYYY\nhh:mm A|zzz (z)")
    .split("|");
};

export enum ConsultationStatus {
  REQUESTED = "Requested",
  DECLINED = "Declined",
  CONFIRMED = "Confirmed",
  RESCHEDULED = "Rescheduled",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PENDING_SCHEDULE = "PENDING_SCHEDULE",
  CANCELLED = "Cancelled",
}

export const statusMap: Record<
  ConsultationStatus,
  { text: string; color: string }
> = {
  [ConsultationStatus.REQUESTED]: { text: "Requested", color: "orange" },
  [ConsultationStatus.RESCHEDULED]: { text: "Rescheduled", color: "green" },
  [ConsultationStatus.DECLINED]: { text: "Declined", color: "red" },
  [ConsultationStatus.CONFIRMED]: { text: "Confirmed", color: "green" },
  [ConsultationStatus.PENDING_PAYMENT]: {
    text: "Pending Payment",
    color: "blue",
  },
  [ConsultationStatus.PENDING_SCHEDULE]: {
    text: "Pending Schedule",
    color: "blue",
  },
  [ConsultationStatus.CANCELLED]: {
    text: "Cancelled",
    color: "gray",
  },
};

export const declineReasons = [
  {
    value: "A scheduling conflict has come up",
    template: 1,
  },
  {
    value: "Topic requires a SHORTER consultation",
    template: 2,
  },
  {
    value: "Topic requires a LONGER consultation",
    template: 3,
  },
  {
    value: "Requires assistance outside of The Field",
    template: 4,
  },
];

export const getRate = (serviceRate: any) => {
  const sponsorshipValid = localStorageGet("info").sponsorshipValid;
  if (serviceRate.priceModel === PriceModel.STANDARD) {
    return serviceRate.rate;
  } else if (
    serviceRate.priceModel === PriceModel.ROLE_TIER &&
    Array.isArray(serviceRate.rate)
  ) {
    if (sponsorshipValid) {
      return serviceRate.rate.find(
        (rate: any) => rate.role === "sponsored_artist"
      )?.rate;
    }
    return serviceRate.rate.find((rate: any) => rate.role === "free_artist")
      ?.rate;
  }
};

export const downloadCsv = (csvData: string) => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `consultations_${dayjs().format("YYYYMMDDHHmmss")}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
