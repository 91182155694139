import { useState } from "react";
import {
  Dropdown,
  Input,
  Menu,
  Table,
  Tag,
  Radio,
  Modal,
  Select,
  Tooltip,
} from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
import { useAntdTable, useRequest } from "ahooks";
import {
  exportConsultationsCSV,
  getConsultations,
  getStaffs,
  updateConsultationStatus,
} from "@/adminApp/apis/consultation-api";
import { ColumnsType } from "antd/lib/table";
import "./list.less";
import {
  ConsultationStatus,
  downloadCsv,
  formatDateTime,
  formatDuration,
  statusMap,
} from "./shared";
import { ReactComponent as SchedulingIcon } from "@/images/icon_scheduling_calendar.svg";
import { ReactComponent as SchedulingListIcon } from "@/images/icon_scheduling_list.svg";
import { ReactComponent as SchedulingSuccessIcon } from "@/images/icon_scheduling_success.svg";
import DeclineModal from "./DeclineModal";
import RescheduleModal from "./RescheduleModal";
import { StaffStatus } from "../staff/shared";
import { uniq } from "lodash";
import BookingsCalendar from "@/pages/dashboard/schedule/BookingsCalendar";
import { useUrlState } from "@/utils/util";
import DatePicker from "@/components/DatePicker";
import { textRender } from "@/components/Ellipsis";
import CancelModal from "./CancelModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { membershipTypes } from "@/types/enum";

export const ConsultationBookingList = () => {
  const [params, setParams] = useUrlState({ mode: "list" });
  const updateStatusRequest = useRequest(updateConsultationStatus, {
    manual: true,
  });
  const [declineOpen, setDeclineOpen] = useState(false);
  const [admin, setAdmin] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [record, setRecord] = useState();
  const [keyword, setKeyword] = useState("");
  const staffsRequest = useRequest(() =>
    getStaffs({ status: StaffStatus.ACTIVE })
  );
  const {
    tableProps,
    refresh,
    params: tableParams,
  } = useAntdTable(
    ({ current, pageSize }) => {
      return getConsultations({
        current,
        pageSize,
        keyword,
        staffId: admin,
        duration,
        startDate,
        endDate,
      });
    },
    {
      refreshDeps: [keyword, admin, duration, startDate, endDate, endDate],
    }
  );
  const updateStatus = async (row: any, status: ConsultationStatus) => {
    await updateStatusRequest.runAsync({
      id: row?.id,
      status,
    });
    refresh();
  };

  const handleExport = async () => {
    const res = await exportConsultationsCSV({
      current: tableParams[0]?.current,
      pageSize: tableParams[0]?.pageSize,
      keyword,
      staffId: admin,
      duration,
      startDate,
      endDate,
      ids: selectedRows.map((item: any) => item.id).join(","),
    });
    downloadCsv(res);
  };

  const column: ColumnsType<any> = [
    {
      title: "ADMIN NAME",
      dataIndex: ["staff", "name"],
      className: "!pl-8 min-w-[130px]",
    },
    {
      title: "ARTIST NAME",
      className: "min-w-[130px]",
      render: (_, record) =>
        `${record.artistUser?.profile?.firstName} ${record.artistUser?.profile?.lastName}`,
    },
    {
      title: (
        <>
          MEMBERSHIP STATUS
          <Tooltip
            overlay={
              <div style={{ padding: 8 }}>
                Note: Expired accounts and crowdfunding campaigns may remain
                Active for 1-2 days after a failed payment attempt.
              </div>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      width: 200,
      dataIndex: "memberShipStatus",
      render: (text: string, row: any) => membershipTypes[text || "free"],
    },
    {
      title: "DURATION",
      dataIndex: "duration",
      className: " min-w-[110px]",
      render: (duration) => formatDuration(duration),
    },
    {
      title: "TOPIC",
      dataIndex: "topic",
    },
    {
      title: "DATE & TIME",
      dataIndex: "startTime",
      className: "!min-w-[150px]",
      render: (duration) => formatDateTime(duration),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: ConsultationStatus) => (
        <Tag color={statusMap[text] ? statusMap[text]?.color : "gray"}>
          {statusMap[text] ? statusMap[text]?.text : text}
        </Tag>
      ),
    },
    {
      title: "ARTIST NOTE",
      dataIndex: "description",
      render: textRender,
      className: "!min-w-[110px] max-w-[200px]",
    },
    {
      title: "ADMIN NOTE",
      dataIndex: "note",
      render: textRender,
      className: "!min-w-[110px] max-w-[200px]",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => {
        return (
          <Dropdown
            trigger={["click"]}
            overlayStyle={{ zIndex: 5 }}
            overlay={() => (
              <Menu className="admin-consultation-staff-menu">
                <Menu.Item
                  key="confirm"
                  disabled={row.status !== ConsultationStatus.REQUESTED}
                  onClick={() => {
                    const modal = Modal.confirm({
                      title: "Are you sure to accept this consultation?",
                      async onOk() {
                        modal.update({ okButtonProps: { loading: true } });
                        await updateStatus(row, ConsultationStatus.CONFIRMED);
                        Modal.info({
                          icon: null,
                          width: 470,
                          content: (
                            <div className="flex flex-col items-center justify-center font-roboto">
                              <SchedulingSuccessIcon className="my-12" />
                              <div className="mb-4 text-[32px] font-[900]">
                                Consultation Accepted
                              </div>
                              <div className="mb-12 text-[16px] w-[374px] text-center">{`You have accepted a consultation with ${
                                row.artistUser.firstName
                              } on ${formatDateTime(
                                row.startTime
                              )}. They will be notified and charged $${Math.max(
                                row.order.amount - row.order.discount,
                                0
                              )}.`}</div>
                            </div>
                          ),
                          okText: "OKAY",
                        });
                      },
                      okButtonProps: {
                        loading: updateStatusRequest.loading,
                      },
                    });
                  }}
                >
                  Confirm
                </Menu.Item>
                <Menu.Item
                  key="decline"
                  disabled={
                    ![
                      ConsultationStatus.REQUESTED,
                      ConsultationStatus.PENDING_PAYMENT,
                      ConsultationStatus.PENDING_SCHEDULE,
                    ].includes(row.status)
                  }
                  onClick={() => {
                    setRecord(row);
                    setDeclineOpen(true);
                  }}
                >
                  Decline
                </Menu.Item>
                <Menu.Item
                  disabled={
                    ![
                      ConsultationStatus.RESCHEDULED,
                      ConsultationStatus.CONFIRMED,
                    ].includes(row.status)
                  }
                  key="reschedule"
                  onClick={() => {
                    setRecord(row);
                    setRescheduleOpen(true);
                  }}
                >
                  Reschedule
                </Menu.Item>
                {[ConsultationStatus.CONFIRMED].includes(row.status) && (
                  <Menu.Item
                    key="cancel"
                    onClick={() => {
                      setRecord(row);
                      setCancelOpen(true);
                    }}
                  >
                    Cancel
                  </Menu.Item>
                )}
              </Menu>
            )}
          >
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <FilterMore />
            </span>
          </Dropdown>
        );
      },
    },
  ];
  const [windowHeight] = useWindowSize();
  const showCalendar = params.mode === "calendar";
  const durations =
    staffsRequest.data?.list?.flatMap((item: any) =>
      item.serviceRates?.map((i: any) => i.duration)
    ) ?? [];
  return (
    <div className="tailwind admin-common-container admin-consultation-staff-list-container">
      <div className="flex justify-between items-start">
        <h1>Consultation Booking</h1>
      </div>
      <div className="flex justify-between items-start relative">
        <div>
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabled={params.mode === "calendar"}
              onChange={(value) => {
                if (value?.[0]) {
                  setStartDate(value[0].startOf("day").toISOString());
                }
                if (value?.[1]) {
                  setEndDate(value[1].endOf("day").toISOString());
                }
              }}
            />
          </div>
          <div className="flex my-7">
            <div className="relative">
              <Select
                className="custom-select-render filter-by-admin"
                options={[
                  { label: "All", value: "" },
                  ...(staffsRequest.data?.list?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? []),
                ]}
                value={admin}
                onChange={(value) => setAdmin(value)}
              />
              <div className="absolute top-[1px] leading-[30px] left-2 text-[14px] pointer-events-none">
                Filter by Admin Name:
              </div>
            </div>
            <div className="relative ml-4">
              <Select
                className="custom-select-render filter-by-duration"
                defaultValue=""
                options={[
                  { label: "All", value: "" },
                  ...uniq(durations).map((d: any) => ({
                    label: formatDuration(d),
                    value: d,
                  })),
                ]}
                value={duration}
                onChange={(value) => setDuration(value)}
              />
              <div className="absolute top-[1px] leading-[30px] left-2 text-[14px] pointer-events-none">
                Filter by Duration:
              </div>
            </div>
          </div>
        </div>
        <Radio.Group
          value={params.mode}
          onChange={(e) => setParams({ mode: e.target.value })}
        >
          <Radio.Button className="size-14 p-4" value="list">
            <SchedulingListIcon className="size-6" />
          </Radio.Button>
          <Radio.Button className="size-14 p-4" value="calendar">
            <SchedulingIcon className="size-6" />
          </Radio.Button>
        </Radio.Group>
        <Dropdown
          className="filter-item absolute bottom-0 right-0 my-7"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {showCalendar ? (
        <BookingsCalendar duration={duration} admin={admin} type="admin" />
      ) : (
        <Table
          className="table-container"
          title={() => (
            <div className="flex pl-4">
              <span>Bookings</span>
              <Input.Search
                onSearch={(keyword: string) => {
                  setKeyword(keyword);
                }}
                placeholder="Search"
              />
            </div>
          )}
          rowKey="id"
          rowSelection={{
            type: "checkbox",
            onChange: (keys, rows) => {
              setSelectedRows(rows);
            },
          }}
          columns={column}
          sticky
          scroll={{
            scrollToFirstRowOnChange: true,
            x: "max-content",
            y: windowHeight - 420,
          }}
          {...tableProps}
          pagination={{ ...tableProps.pagination, showSizeChanger: true }}
          loading={tableProps.loading}
        />
      )}
      <DeclineModal
        open={declineOpen}
        setOpen={setDeclineOpen}
        refresh={refresh}
        record={record}
      />
      <CancelModal
        open={cancelOpen}
        setOpen={setCancelOpen}
        refresh={refresh}
        record={record}
      />
      <RescheduleModal
        open={rescheduleOpen}
        setOpen={setRescheduleOpen}
        setRecord={setRecord}
        refresh={refresh}
        record={record}
      />
    </div>
  );
};
