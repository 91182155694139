import React from "react";
import ReactDOM from "react-dom";
import { AdminApp } from "./adminApp";
import "./styles/tailwind/output.css";

// this is the entry of admin portal

//AWS S3
const AWS = (window as any)?.AWS;
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  }),
});
//AWS S3 -- End

ReactDOM.render(
    <React.StrictMode>
        <AdminApp />
    </React.StrictMode>,
    document.getElementById("root")
);