import { useHistory } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { ReactComponent as LogoWhite } from "@/images/logo-white.svg";
import "@/styles/sign.less";
import { adminSetPassword } from "@/adminApp/apis/user-api";
import { useRequest } from "ahooks";
import { useSearchParam } from "react-use";
import { useModel } from "use-reaction";
import { model_admin_user } from "@/adminApp/models/model_admin_user";
import { setToken } from "@/utils/storage";
import Password from "@/components/Password";
import { showErrorMessage } from "@/utils/util";

const AdminSetPassword = () => {
  const history = useHistory();
  const token = useSearchParam("token");
  const setPasswordRequest = useRequest(adminSetPassword, { manual: true });
  const { resetModel } = useModel(model_admin_user);
  const onFinish = (values: any) => {
    const { password } = values;
    setPasswordRequest
      .runAsync({
        password,
        token,
      })
      .then(async () => {
        setToken("");
        resetModel();
        message.success("Password set successfully! Redirecting to sign in...");
        setTimeout(() => {
          history.push("/sign/in");
        }, 1000);
      })
      .catch(showErrorMessage);
  };
  return (
    <div className="sign-container sign-up-container password-container sign-in-container admin-set-password-container flex start">
      <div className="left-wrap">
        <span>
          <LogoWhite />
        </span>
        <i className="copy-right">© Artist Alicia Foxworth</i>
      </div>
      <div className="change-pwd-container recover-container sign-up-wrap  flex column start">
        <h1>Set password</h1>
        <h4>
          Please enter password and confirm one more time to complete the
          settings.
        </h4>
        <div className="sign-form">
          <Form
            layout="vertical"
            size="large"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{ width: "100%" }}
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Enter your new password again" />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={setPasswordRequest.loading}
              >
                Update password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminSetPassword;
