import { useState } from "react";
import {
  Dropdown,
  Input,
  Menu,
  message,
  Table,
  Tag,
  Button,
  AutoComplete,
  Modal,
} from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useHistory } from "react-router-dom";
import {
  admin_router_consultation_staff_create,
  admin_router_consultation_staff_edit,
} from "@/adminApp/routers";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
import { useAntdTable, useRequest } from "ahooks";
import {
  deleteStaff,
  getConsultationTopics,
  getStaffs,
  updateStaff,
} from "@/adminApp/apis/consultation-api";
import { ColumnsType } from "antd/lib/table";
import "./list.less";
import { StaffStatus, staffStatusMap } from "./shared";
import { useDebounce } from "ahooks";
import EditTopicModal from "../topic/EditTopicModal";

export const ConsultationStaffList = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const topicsRequest = useRequest(getConsultationTopics);
  const updateStaffRequest = useRequest(updateStaff, { manual: true });
  const deleteStaffRequest = useRequest(deleteStaff, { manual: true });
  const [topic, setTopic] = useState("");
  const debouncedTopic = useDebounce(topic, { wait: 500 });
  const [keyword, setKeyword] = useState("");
  const { tableProps, refresh } = useAntdTable(
    ({ current, pageSize }) => {
      return getStaffs({
        current,
        pageSize,
        topic: debouncedTopic,
        keyword,
      });
    },
    {
      refreshDeps: [debouncedTopic, keyword],
    }
  );

  const openEdit = (id: string) => {
    history.push(admin_router_consultation_staff_edit.replace(":id", id));
  };

  const handleActive = async (row: object, flag = true) => {
    await updateStaffRequest.runAsync({
      ...row,
      status: flag ? StaffStatus.ACTIVE : StaffStatus.INACTIVE,
    });
    message.success("Staff status updated successfully!");
    refresh();
  };

  const handleDelete = async (row: any) => {
    Modal.confirm({
      title: "Delete staff",
      content: "Are you sure you want to delete this staff?",
      onOk: async () => {
        const res = await deleteStaffRequest.runAsync(row.id);
        if (res) {
          message.success("Staff deleted successfully!");
          refresh();
        }
      },
    });
  };

  const column: ColumnsType<any> = [
    {
      title: "ADMIN NAME",
      dataIndex: "name",
      className: "!pl-8 min-w-[130px]",
    },
    {
      title: "TOPIC",
      dataIndex: "topics",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: StaffStatus) => (
        <Tag
          color={staffStatusMap[text] ? staffStatusMap[text]?.color : "gray"}
        >
          {staffStatusMap[text] ? staffStatusMap[text]?.text : text}
        </Tag>
      ),
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <Menu className="admin-consultation-staff-menu">
              <Menu.Item key="edit" onClick={() => openEdit(row.id)}>
                Edit info
              </Menu.Item>
              {row.status === StaffStatus.ACTIVE && (
                <Menu.Item
                  key="active"
                  onClick={(event) => handleActive(row, false)}
                >
                  Mark inactive
                </Menu.Item>
              )}
              {row.status === StaffStatus.INACTIVE && (
                <Menu.Item
                  key="inactive"
                  onClick={() => handleActive(row, true)}
                >
                  Mark active
                </Menu.Item>
              )}
              <Menu.Item key="delete" onClick={() => handleDelete(row)}>
                Delete staff
              </Menu.Item>
            </Menu>
          )}
        >
          <span
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [windowHeight] = useWindowSize();
  return (
    <div className="tailwind admin-common-container admin-consultation-staff-list-container">
      <h1 className="flex !mb-4 color-[#4A4A4A]">
        Staff List
        <Button
          type="primary"
          onClick={() => history.push(admin_router_consultation_staff_create)}
        >
          Add new Staff
        </Button>
      </h1>
      <div className="mb-8">
        Add the information for a staff that will appear in the consultation
        booking.
      </div>
      <div className="search-container flex">
        <div className="field-form-items">
          <span>Topic</span>
          <AutoComplete
            className="tf-autocomplete"
            style={{ width: 200 }}
            options={topicsRequest.data?.list?.map((item: any) => ({
              value: item.title,
            }))}
            placeholder="Type or select a topic"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            allowClear
            value={topic}
            onChange={setTopic}
          />
        </div>
        <Button style={{ marginLeft: "auto" }} onClick={() => setOpen(true)}>
          Add New Topic
        </Button>
      </div>
      <Table
        className="table-container"
        title={() => (
          <div className="flex pl-4">
            <span>Staffs</span>
            <Input.Search
              onSearch={(keyword: string) => {
                setKeyword(keyword);
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        columns={column}
        sticky
        scroll={{
          scrollToFirstRowOnChange: true,
          x: "max-content",
          y: windowHeight - 420,
        }}
        {...tableProps}
        pagination={{ ...tableProps.pagination, showSizeChanger: true }}
      />
      <EditTopicModal
        open={open}
        setOpen={setOpen}
        refresh={() => history.push("/consultation/topic")}
      />
    </div>
  );
};
